import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UniversalApiCallsService } from '../service/universal/universal-api-calls.service';

@Component({
  selector: 'app-logout-redirect',
  templateUrl: './logout-redirect.component.html',
  styleUrls: ['./logout-redirect.component.scss'],
})
export class LogoutRedirectComponent implements OnInit {

  logoutUrl: string | null = null;
  originUrl = environment.fqdns;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private universalApiCallsService: UniversalApiCallsService,
  ) { }

  ngOnInit(): void {
    const headers = new HttpHeaders({
      Origin: this.originUrl
    })
    this.route.queryParams.subscribe(param => {
      let appCheck = param['app'];
      if (appCheck) {
        this.universalApiCallsService.getAppsData().subscribe(data => {
          const item = data.find((obj: { name: string; baseUrls: { logout: string; } }) => obj.name === appCheck);
          if (item?.name) {
            this.logoutUrl = item.baseUrls.logout;
          } else {
            this.logoutUrl = environment.cb_logoutUrl; // currently default is cbforex
          }
          this.http.get(this.logoutUrl, { headers, responseType: 'blob' }).subscribe(() => {
          },
          (error) => { 
            console.log('Error loading png file') 
          })
        })
      } else {
        this.logoutUrl = environment.cb_logoutUrl; // currently default is cbforex
        this.http.get(this.logoutUrl, { headers, responseType: 'blob' }).subscribe(() => {
        },
        (error) => { 
          console.log('Error loading png file') 
        })
      }
    })
    setTimeout(() => {
      window.close();
    }, 5000)
  }
}
