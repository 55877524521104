import { Injectable, ViewContainerRef } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { XmSdk } from '../../../transmit2/xm/js-es6/xmsdk-es6.js';
import { UniversalTransmitUiHandler } from '../../transmit/universal/universal-transmit-ui-handler';
import { ThreatMetrixService } from '../beta/threat-metrix.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UniversalSessionService } from '../../core/service/universalSession.service';
import { UniversalApiCallsService } from 'src/app/service/universal/universal-api-calls.service';
import { ApplicationService } from '../../core/service/application.service';
import { sharedState } from "@citizens/mfe-shared-state";

@Injectable({
  providedIn: 'root'
})
export class TransmitUniversalService {

  private readonly TRANSMIT_UI_CONTAINER_ID = 'appContent';
  private viewContainerRef: ViewContainerRef;
  private loginComp: any;
  private tmxUnivSessionId: string;
  private profiledUrl: string;
  public transmitSDK = XmSdk();
  private sessionExpired = false;
  private relayStateLimit = 1024;

  constructor(private ssoUniversalUiHandler: UniversalTransmitUiHandler,
    private threatMetrixService: ThreatMetrixService,
    private http: HttpClient,
    private sessionService: UniversalSessionService,
    private universalApiCallsService: UniversalApiCallsService,
    private appService: ApplicationService
  ) { 
    sharedState.setApplicationDataSub("transmitInstance", { sdk: this.transmitSDK });
  }

  initializeTransmit(transmitUrl, viewContainerRef, loginComp, applicationName) {
    this.viewContainerRef = viewContainerRef;
    this.loginComp = loginComp;
    this.initJourneyPlayer(transmitUrl, applicationName);
  }

  authenticate(userId, companyId, legacy_uid, universalId, password, appName, journeyName, tmxUnivSessionId, profiledUrl, sfTargetPath, preProdValue, legacyEmailId): Promise<any> {
    this.tmxUnivSessionId = tmxUnivSessionId;
    this.profiledUrl = profiledUrl;
    // const username = `${userId}_${companyId}_${appName}`;
    let username = undefined;

    username = `${userId}`;
    if (companyId) {
      username += `_${companyId}`;
    }
    if (appName) {
      username += `_${appName}`;
    }

    return this.login(username, legacy_uid, universalId, password, journeyName, tmxUnivSessionId, profiledUrl, sfTargetPath, preProdValue, legacyEmailId, companyId);
  }

  /**
   *  login adds on the additional params and starts the transmit authentication process
   * @param username      made up of useId_companyId_appName
   * @param journeyName   journey name
   * @param tmxUnivSessionId     tmxUnivSessionId
   **/
  async login(username, legacy_uid, universalId, passtext, journeyName, tmxUnivSessionId, profiledUrl, sfTargetPath, preProdValue, legacyEmailId, companyId): Promise<com.ts.mobile.sdk.AuthenticationResult> {
    const componentSelector = (this.viewContainerRef) ? (this.viewContainerRef['_hostLView'][0].localName) : undefined;

    if (componentSelector && (componentSelector == 'app-forgot-password-universal' || componentSelector == 'app-universal-login')) {
      this.isLoggedIn();
    }
    if (passtext !== undefined) {
      this.sessionService.setIsUniversalLoginFlow(true);
    }
    const clientContext = {
      uiContainer: document.getElementById(this.TRANSMIT_UI_CONTAINER_ID),
      viewContainerRef: this.viewContainerRef,
      loginComp: this.loginComp,
      passtext: passtext
    };
    const additionalParams = {
      'tm_session_id': tmxUnivSessionId,
      'profile_Url': profiledUrl
    };

    let userdetails = undefined;
    let relayState = sessionStorage.getItem('relayState');
    switch (journeyName) {
      case 'login':
      case 'trouble_login':
      case 'update_profile': {
        userdetails = universalId; break;
      }
      case 'sfdc_ping_dropoff': {
        userdetails = universalId;
        additionalParams['companyName'] = username;
        additionalParams['persistentId'] = legacy_uid ? legacy_uid : companyId;
        additionalParams['sfTargetPath'] = relayState && relayState.length < this.relayStateLimit  ? relayState : sfTargetPath;

        try {
          const response = await this.universalApiCallsService.getContactId(universalId, legacy_uid).toPromise();
          additionalParams['contactID'] = response.record.Id;
        } catch (error) {
          console.log(error);
        }

        if (environment.isSIT) {
          additionalParams['sso_env'] = 'sit';
        }
        break;
      }
    }
    if (!(journeyName == 'update_profile' || journeyName == 'sfdc_ping_dropoff')) {
      return this.transmitSDK.authenticate(userdetails, journeyName, additionalParams, clientContext);
    } else {
      return this.transmitSDK.invokePolicy(journeyName, additionalParams, clientContext);
    }
  }

  updateSplashDetailsTransmitInvokePolicy(mobileNumber, landineNumber, journeyName) {
    const clientContext = {
      uiContainer: document.getElementById(this.TRANSMIT_UI_CONTAINER_ID),
      viewContainerRef: this.viewContainerRef,
      loginComp: this.loginComp
    };

    const additionalParams = {
      'splashUpdate': true,
      'newMobileNumber': mobileNumber,
      'newHomeNumber': landineNumber
    };
    return this.transmitSDK.invokePolicy(journeyName, additionalParams, clientContext);
  }

  addCredentialsTransmitInvokePolicy(userDetails, transmitAppId, journeyName, tmxUnivSessionId, profiledUrl) {
    const clientContext = {
      uiContainer: document.getElementById(this.TRANSMIT_UI_CONTAINER_ID),
      viewContainerRef: this.viewContainerRef,
      loginComp: this.loginComp
    };
    const additionalParams = {
      'tm_session_id': tmxUnivSessionId,
      'profile_Url': profiledUrl
    };
    additionalParams['legacy_uid'] = userDetails;
    additionalParams['appId'] = transmitAppId;
    return this.transmitSDK.invokePolicy(journeyName, additionalParams, clientContext);
  }

  removeCredentialsTransmitInvokePolicy(legacy_uid, journeyName, appName, webAppId) {
    const clientContext = {
      uiContainer: document.getElementById(this.TRANSMIT_UI_CONTAINER_ID),
      viewContainerRef: this.viewContainerRef,
      loginComp: this.loginComp
    };

    const additionalParams = {};

    // if (environment.nonOUDApps.includes(appName)) {

    // config
    if (this.appService.getNonOUDAppsList()?.includes(appName)) {
      additionalParams['non_legacy_app_name'] = webAppId;
      additionalParams['legacy_uid'] = legacy_uid;
    } else {
      additionalParams['legacy_uid'] = legacy_uid;
    }
    return this.transmitSDK.invokePolicy(journeyName, additionalParams, clientContext);
  }

  universalTransmitInvoke(userDetails, journeyName, nonLegacyWebApp, tmxUnivSessionId, profiledUrl) {
    const clientContext = {
      uiContainer: document.getElementById(this.TRANSMIT_UI_CONTAINER_ID),
      viewContainerRef: this.viewContainerRef,
      loginComp: this.loginComp
    };
    const additionalParams = {
      'tm_session_id': tmxUnivSessionId,
      'profile_Url': profiledUrl,
      'legacy_uid': userDetails,
      'non_legacy_app_name': nonLegacyWebApp,
      'appId': nonLegacyWebApp
    };
    return this.transmitSDK.invokePolicy(journeyName, additionalParams, clientContext);
  }

  universalTransmitNonLegacyAddAccount(userDetails, journeyName, nonLegacyWebApp, tmxUnivSessionId, profiledUrl) {
    const clientContext = {
      uiContainer: document.getElementById(this.TRANSMIT_UI_CONTAINER_ID),
      viewContainerRef: this.viewContainerRef,
      loginComp: this.loginComp
    };
    const additionalParams = {
      'tm_session_id': tmxUnivSessionId,
      'profile_Url': profiledUrl,
      'legacy_uid': userDetails,
      'non_legacy_app_name': nonLegacyWebApp
    };
    return this.transmitSDK.invokePolicy(journeyName, additionalParams, clientContext);
  }

  universalEnrollmentTransmitAuthenticate(userdetails, journeyName, transmitAppId, tmxUnivSessionId, profiledUrl, formData, appName) {
    console.log(userdetails, journeyName, transmitAppId, tmxUnivSessionId, profiledUrl, formData, appName)
    this.isLoggedIn();
    const clientContext = {
      uiContainer: document.getElementById(this.TRANSMIT_UI_CONTAINER_ID),
      viewContainerRef: this.viewContainerRef,
      loginComp: this.loginComp
    };
    const additionalParams = {
      'tm_session_id': tmxUnivSessionId,
      'profile_Url': profiledUrl
    };
    additionalParams['link_credentials'] = true;
    additionalParams['appId'] = transmitAppId;

    // config
    if (this.appService.getNonOUDAppsList()?.includes(appName)) {
      let currentAppFieldsData = this.appService.getConfigAppsData(appName).enrollmentFields;
      currentAppFieldsData.forEach((val: any) => {
        if (val.fieldName.includes('userId')) {
          additionalParams['userId'] = formData.userId.toUpperCase();
        } else if (val.fieldName.includes('emailId') && val.fieldName.includes('accountId')) {
          additionalParams['emailAddress'] = formData.email.toUpperCase();
          additionalParams['companyId'] = formData.compId.toUpperCase();
        }
      })
    }

    additionalParams['applicationName'] = appName;
    return this.transmitSDK.authenticate(userdetails, journeyName, additionalParams, clientContext);
  }

  userEnrollmentTransmitAuthenticate(universalId, tmxUnivSessionId, profiledUrl, journeyName) {
    this.isLoggedIn();

    const clientContext = {
      uiContainer: document.getElementById(this.TRANSMIT_UI_CONTAINER_ID),
      viewContainerRef: this.viewContainerRef,
      loginComp: this.loginComp
    };
    const additionalParams = {
      'tm_session_id': tmxUnivSessionId,
      'profile_Url': profiledUrl
    };
    return this.transmitSDK.authenticate(universalId, journeyName, additionalParams, clientContext);
  }

  pingDropOffTransmitInvokePolicy(userId, compId, appName, webAppId, legacyId, journeyName, universalId, legacyEmailId, preProdValue, nonOUDAppDetails) {
    const clientContext = {
      uiContainer: document.getElementById(this.TRANSMIT_UI_CONTAINER_ID),
      viewContainerRef: this.viewContainerRef,
      loginComp: this.loginComp
    };
    const additionalParams = {};
    if (this.appService.getNonOUDAppsList().includes(appName)) {
      additionalParams['companyID'] = compId || '';
      additionalParams['appId'] = webAppId || '';
      additionalParams['userId'] = userId || '';
      additionalParams['legacy_uid'] = legacyId || '';
      // include DACA changes
      if (this.appService.getConfigAppsData(appName)?.usesTargetUrl) {
        const url = this.appService.getConfigAppsData(appName)?.baseUrls?.sfTarget;
        additionalParams['sfTargetPath'] = url || '';
      }
    } else {
      additionalParams['legacy_uid'] = legacyId || '';
    }

    if (environment.isSIT) {
      additionalParams['sso_env'] = 'sit';
    }
    //Preprod Environment setup
    if (environment.isQA && sessionStorage.getItem('preprod')) {
      additionalParams['sso_env'] = preProdValue;
    }
    return this.transmitSDK.invokePolicy(journeyName, additionalParams, clientContext);
  }

  initJourneyPlayer(transmitUrl, applicationName) {
    const settings = this.getTransmitConnectionSettings(transmitUrl, applicationName);
    this.transmitSDK.setConnectionSettings(settings);
    this.transmitSDK.setUiHandler(this.ssoUniversalUiHandler);
    this.transmitSDK.initialize()
      .then((results) => {
        const componentSelector = (this.viewContainerRef) ? (this.viewContainerRef['_hostLView'][0].localName) : undefined;
        if (componentSelector && (componentSelector == 'app-forgot-password-universal' || componentSelector == 'app-identity-registration' || componentSelector == 'app-create-universal-login-profile')) {
          this.isLoggedIn();
        }
      })
      .catch((error) => {
        console.error(`TS: Transmit SDK initialization error!: ${error}`);
      });
  }

  getTransmitConnectionSettings(serverUrl, applicationName) {
    const appName = applicationName;
    const tokenName = undefined;
    const token = undefined;
    return com.ts.mobile.sdk.SDKConnectionSettings.create(serverUrl, appName, tokenName, token);
  }

  async isLoggedIn() {
    //    const users = this.transmitSDK.getUsersInfo();
    //   if (users.length >= 1) {
    await this.logout();
    //   }
  }

  async logout(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      // TODO remove any cookies ??
      await this.transmitSDK.logout()
        .then((success: boolean) => {
          // TODO remove token if stored ??
          resolve(true);
        })
        .catch((error) => {
          console.log(`TS: logout: ${error}`);
        });
    });
  }

  checkUniversalIdAvailability(universalIdToCheck: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: environment.transmitToken
      })
    };
    const body = { 'policy_request_id': 'check_univ_id_availability' };

    return this.http.post(environment.transmitUniversalIdAvailabilityUrl + universalIdToCheck, body, httpOptions);
  }

  getContactInfo(universalId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: environment.transmitToken
      })
    };
    const body = { 'policy_request_id': 'univ_contact_info' };

    return this.http.post(environment.transmitUniversalIdAvailabilityUrl + universalId, body, httpOptions);
  }

  setTransmitSessionExpired(isExpired: boolean) {
    this.sessionExpired = isExpired;
  }

  getTransmitSessionExpired(): boolean {
    return this.sessionExpired;
  }

}